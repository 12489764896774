import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import "../styles/reviews.sass";

const ReviewsPage = ({ data }) => {
	return (
		<Layout>
			<div className="reviews">
				<div className="container">
					<h2 className="is-size-2">Reviews</h2>
					<p>
						“Wonderful book. At once profound and amusing.”{" "}
						<em>- John C. Bogle, founder and former CEO of Vanguard.</em>
					</p>

					<p>
						“You can't start too early to teach kids lifelong lessons of
						financial literacy while putting money in its proper place. Rob
						Pivnick has succeeded admirably presenting timeless lessons for
						saving, investing and much more, explained with clarity, humor, and
						grace.{" "}
						<span>Kids and their parents could not find a better guide.</span>
						”{" "}
						<em>- Burton G. Malkiel, author, A Random Walk down Wall Street</em>
					</p>

					<p>
						"Pivnick’s book is amazingly well written – all middle school and
						high school students should be taught the concepts that Pivnick lays
						out so nicely in this book. Beyond that, it actually should be read
						by all adults who are not fully comfortable with their savings and
						investing skills. <span>Kudos to the author.</span>"{" "}
						<em>
							- Gregg Fleisher, Chief Academic Officer, National Math + Science
							Initiative
						</em>
					</p>

					<p>
						“Ben Franklin wrote an ‘an investment in knowledge pays the best
						interest.’ The return to any high school kid from reading What All
						Kids Should Know About . . . Saving and Investing should be
						enormous. Pivnick makes an otherwise boring topic great fun and{" "}
						<span>potentially genuinely life changing.</span>”{" "}
						<em>
							- Paul Merriman, author and founder of Merriman Inc. advisory firm
							and The Merriman Financial Education Foundation
						</em>
					</p>

					<p>
						“<u>What All Kids...</u> is a straightforward but entertaining book
						that covers all the basics your kids need to know about finances.
						Buy it for your kids so they can get their financial future started
						off on the right foot!” <em>- Kirsten, TheFrugalGirl</em>
					</p>

					<p>
						“Pivnick’s book is a great resource. If you want to improve your
						money management skills, with some great ideas on how you can
						improve your financial situation, this book is a{" "}
						<span>great addition to your home library</span> for adults and
						teens.”{" "}
						<em>
							- Miranda Marquit, Financial Journalist and Money Expert, Planting
							Money Seeds
						</em>
					</p>

					<p>
						“
						<span>
							If you are a parent looking for a great book for your son or
							daughter, this book is it.
						</span>{" "}
						A wonderful guide for beginners to the world of saving and
						investing, Pivnick’s book covers all the topics your children need
						to know with the right amount of detail, graphs and fun narratives
						to keep them interested.”{" "}
						<em>
							- Barbara Friedberg, MBA, MS, author of How to Get Rich; Without Winning the Lottery
						</em>
					</p>

					<p>
						“Pivnick’s book has a lot of great things and important concepts
						that make it interesting for middle school, high school and
						millennials . . . it’s an excellent book for a lot of people. And
						don’t let the name fool you; this book could easily serve as the
						first book for anybody - kid or adult – that doesn’t know about
						money and saving.” <em>- Rob Berger, The Dough Roller</em>
					</p>

					<p>
						“If you are looking for a single, efficient, comprehensive text with
						which to lay out the right money ideas for your kids, have
						confidence that <span>Pivnick’s book is spot on perfect.</span>”{" "}
						<em>
							- Michael Taylor, Finance Columnist for San Antonio Express News
							and Finance Blogger at Bankers Anonymous
						</em>
					</p>

					<hr style={{ marginTop: `3em` }} />
					<h2 className="is-size-2">More Reviews</h2>
					<p>
						<a
							target="_blank"
							rel="noreferrer"
							href="https://www.expressnews.com/business/business_columnists/michael_taylor/article/Teaching-kids-and-do-s-and-don-ts-of-investing-16081683.php"
						>
							San Antonio Express-News, April 7, 2021.
						</a>
					</p>
					<p>
						<a
							target="_blank"
							rel="noreferrer"
							href="https://www.expressnews.com/business/article/FinLit-For-The-Kids-13486925.php"
						>
							FinLit For The Kids, December 20, 2018.
						</a>
					</p>
					<p>
						<a
							target="_blank" rel="noreferrer"
							href="http://www.doughroller.net/book-reviews/what-all-kids-should-know-about-money"
						>
							Dough Roller, November 18, 2020.
						</a>
					</p>
					<p>
						<a
							target="_blank" rel="noreferrer"
							href="http://plantingmoneyseeds.com/kids-adults-know-saving-investing/ "
						>
							Planting Money Seeds. Miranda Marquit. December 29, 2014.
						</a>
					</p>
				</div>
			</div>
		</Layout>
	);
};

export default ReviewsPage;

export const query = graphql`
	query Reviews {
		allImageSharp {
			nodes {
				resize {
					src
					width
					originalName
				}
				fluid(quality: 100, sizes: "1200,800") {
					srcSet
					src
					originalName
					sizes
				}
			}
		}
	}
`;
